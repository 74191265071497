@import '../../../assets/styles/_variables.scss';

.AudioButton {
    display: flex;

    box-sizing: border-box;
    // max-height: 48px;
    margin-bottom: 5px;

    cursor: initial;
    text-align: left;
    flex: 0 1 auto;

    .PlayerButton {
        display: flex;

        margin-right: 15px;

        cursor: pointer;

        outline: 0;
        align-items: center;

        i {
            font-size: $font-size-large;
        }
    }

    .ButtonText {
        overflow: hidden;

        text-overflow: ellipsis;
    }
}

@import './assets/styles/_variables';

:global {
    @import '~font-awesome/scss/font-awesome';
}

html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
}

body {
    background: $primary-color;
}

:global(#app) {
    font-family: 'Montserrat', sans-serif;
    font-size: $font-size-medium;
    font-weight: 500;

    color: $white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:global {
    body {
        @each $name, $props in $groups {
            &.#{$name} {
                background: lighten(map-get($props, 'color-light'), 10%);
            }
        }
    }
}

img {
    -ms-interpolation-mode: bicubic;
}

*:focus {
    outline: none;
}

.App {
    height: 100%;
}

*:focus {
    outline: none;
}

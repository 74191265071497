// Base colors
$white: #fff;
$gray: #e9e9e9;
$primary-color: #00a887;
$primary-light-color: #47c0a9;

// Group colors
$light-red-color: #ff839e;
$dark-red-color: #f02753;
$dark-blue-color: #0a50a3;

$groups: (
    group12:
    (
        color: #f02753,
        color-lighter: #ff97ad,
        color-light:#ff5c7f,
        color-dark: #c6153c
    ),
    group3:
    (
        color: #ff7c05,
        color-lighter: #ffd005,
        color-light: #ffa605,
        color-dark: #f29500
    ),
    group4:
    (
        color: #0a50a3,
        color-lighter: #54a3ff,
        color-light: #0a76f6,
        color-dark: #002d63
    )
);

// General colors
$color-error-background: $light-red-color;
$color-success-background: darken(#12A786, 10%);
$menu-bullet-color: $dark-red-color;

// text colors
$text-color: #101010;

// button colors
$button-primary-color: #66cab7;
$button-lesson-color: $dark-blue-color;
$button-lesson-highlight-color: #b2d6ff;

// borders
$border-primary: #e9e9e9;

// Font sizes
$font-size-smallest: 10px;
$font-size-small: 12px;
$font-size-medium: 14px;
$font-size-medium-large: 16px;
$font-size-large: 20px;
$font-size-largest: 26px;

// font awesome path
$fa-font-path: '~font-awesome/fonts';
